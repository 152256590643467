import React from "react";

import {
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';


const AnswerEdit: React.FC = props => (
    <Create {...props} title="Новый вопрос">
        <SimpleForm redirect="/answer">
            <TextInput source="name" multiline label="Вопрос"/>
        </SimpleForm>
    </Create>
);

export default AnswerEdit