import React from "react";
import jsonServerProvider from 'ra-data-json-server';
import {Admin, Resource, downloadCSV} from 'react-admin';
import AnswerList from "./components/Answer/AnswerList";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import AnswerEdit from "./components/Answer/AnswerEdit";
import AnswerCreate from "./components/Answer/AnswerCreate";
import CustomLayout from "./components/Layout/CustomLayout";
import CustomDashboard from "./components/Layout/CustomDashboard";
import authProvider from "./components/authProvider";
import LoginPage from "./components/Layout/LoginPage";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {fetchUtils} from 'react-admin';

// @ts-ignore
import russian from "ra-language-russian"
import {stringify} from "querystring";
import RegistrationList from "./components/Registration/RegistrationList";
import RegistrationShow from "./components/Registration/RegistrationShow";
import createHistory from 'history/createBrowserHistory';

const history = createHistory();

const apiUrl = process.env.REACT_APP_SERVER_URL


const httpClient = (url: string, options: any = {}) => {
    options.credentials = "include"
    return fetchUtils.fetchJson(url, options)
}

const messages = {
    ru: russian
};

// @ts-ignore
const i18nProvider = polyglotI18nProvider(locale => messages[locale], "ru");

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#FF0000",
        },
        secondary: {
            main: "#FF0000",
        },
    }
});


const baseUrl = process.env.REACT_APP_SERVER_URL as string;
const dataProvider = jsonServerProvider(baseUrl, httpClient);

// @ts-ignore
dataProvider.getOne = () => {
    return new Promise(((resolve, reject) => {
        return reject
    }))
}


// @ts-ignore
dataProvider.getList = (resource, params) => {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    // @ts-ignore
    return httpClient(url).then(({headers, json}) => {
        const header = headers.get('x-total-count')

        let ans: string[]
        if (header !== null) {
            ans = header.split('/')
        } else ans = []

        return json ? {
            data: json.data.map((resource: { _id: any; }) => ({...resource, id: resource._id})),
            total: parseInt(ans.pop() as string, 10)
        } : {data: {}}
    })
}


const App: React.FC = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <Admin dataProvider={dataProvider}
                   layout={CustomLayout}
                   history={history}
                   dashboard={CustomDashboard}
                   theme={theme}
                   i18nProvider={i18nProvider}
                   loginPage={LoginPage}
                   authProvider={authProvider}>
                <Resource name="answer" list={AnswerList} edit={AnswerEdit} create={AnswerCreate}/>
                <Resource name="registration" list={RegistrationList} show={RegistrationShow}/>
            </Admin>
        </MuiThemeProvider>
    )
};

export default App;
