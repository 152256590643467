import * as React from 'react';
import { MenuItemLink, DashboardMenuItem } from 'react-admin';
import HelpIcon from '@material-ui/icons/Help';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';


const Menu = () => (
    <div>
        <MenuItemLink to="/" primaryText="Главная" leftIcon={<HomeIcon />}/>
        <MenuItemLink to="/answer" primaryText="Вопросы" leftIcon={<HelpIcon />}/>
        <MenuItemLink to="/registration" primaryText="Заявки" leftIcon={<PeopleIcon />}/>

        {/*<MenuItemLink to="/users" primaryText="Вопросы" leftIcon={<HelpIcon />}/>*/}

    </div>
);

export default Menu