import * as React from "react";
import { Card, CardHeader } from '@material-ui/core';
import cat from "../../cat.svg"

export default () => (
    <Card>
        <CardHeader title="Администрирование сайта Магнит-Кодекс" />
        <img src={cat} alt="cat"/>
        {/*<CardContent>Lorem ipsum sic dolor amet...</CardContent>*/}
    </Card>
);