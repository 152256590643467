import axios from "axios";

const obj = axios.create({baseURL: process.env.REACT_APP_SERVER_URL,  withCredentials: true})

export default {
    // called when the user attempts to log in
    login: async ({username, password}) => {
        let allow = false
        await obj.post("login", {"email": username, "password": password})
            .catch(() => Promise.reject())
        await obj.get("checkLogin")
            .then(res => {
                res.data.isAuth ? allow = true : allow = false
            })
        localStorage.setItem('username', username);
        if (allow) return Promise.resolve()
        else return Promise.reject()
    },
    // called when the user clicks on the logout button
    logout: async () => {
        await obj.get("logout")
            .then()
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status}) => {
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        return obj.get("checkLogin")
            .then(res => {
                return res.data.isAuth ? Promise.resolve() : Promise.reject();
            })
            .catch(() => Promise.reject())
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};