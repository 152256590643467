import React from "react";

import {
    Edit,
    SimpleForm,
    TextInput
} from 'react-admin';


const AnswerEdit: React.FC = props => (
    <Edit {...props} title="Редактирование вопроса">
        <SimpleForm>
            <TextInput source="name" multiline label="Вопрос"/>
        </SimpleForm>
    </Edit>
);

export default AnswerEdit