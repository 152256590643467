import * as React from "react";
import {List, Datagrid, TextField, downloadCSV } from 'react-admin';
import CustomPagination from "../Layout/CustomPagination";
import { unparse as convertToCSV } from 'papaparse';


// const exporter = questions => {
//     const postsForExport = answers.map(answer => {
//         // add a field from an embedded resource
//         post.author_name = post.author.name;
//         return postForExport;
//     });
//     const csv = convertToCSV({
//         data: postsForExport,
//         // select and order fields in the export
//         fields: ['id', 'title', 'author_name', 'body']
//     });
//     downloadCSV(csv, 'posts'); // download as 'posts.csv` file
// })


// @ts-ignore
const AnswerList = (props) => {
    return (

        <List {...props} title="Вопросы" exporter={false} pagination={<CustomPagination/>} perPage={5}>
            <Datagrid rowClick="edit">
                <TextField label="Вопрос" source="name" component="pre"/>
            </Datagrid>
        </List>


    )
}

export default AnswerList