import React from "react";
import {Layout} from 'react-admin';
import Menu from "../Menu";
import CustomAppBar from "./CustomAppBar";
import {Notification} from 'react-admin';
import {useAuthState, Loading} from 'react-admin';


// @ts-ignore
const MyNotification: React.FC = props => <Notification {...props} autoHideDuration={0}
                                                        onError={() => console.log("eerr")}/>;

const CustomLayout: React.FC = (props) => {
    const {loading, authenticated} = useAuthState();

    return (
        <>
            {loading?
            <Loading/>:
                <Layout {...props} menu={Menu}
                        appBar={CustomAppBar}
                        notification={MyNotification}
                        style={authenticated? {}: {display: "none"}}/>
            }

        </>
    )
};

export default CustomLayout