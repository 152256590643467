import * as React from "react";
import { List, Datagrid, TextField, ReferenceField, Resource, ListGuesser, downloadCSV } from 'react-admin';
import CustomPagination from "../Layout/CustomPagination";
import { unparse as convertToCSV, ParseConfig, parse } from 'papaparse';
import {utils, write} from 'xlsx';
import { saveAs } from "file-saver";

var flatten = require('flat')

// const exporter = (registers : any)  => {
//     const registerForExport = registers.map( (register : any) => {
//         // add a field from an embedded resource
//         register.lastname = register.lastName;
//         return registerForExport;
//     });
//     const csv = convertToCSV({
//         data: registerForExport,
//         // select and order fields in the export
//         fields: ['lastname']
//     });
//     downloadCSV(csv, 'registers'); // download as 'posts.csv` file
// }

function encode_utf8(s : string) {
    return unescape(encodeURIComponent(s));
  }
  
  function decode_utf8(s : string) {
    return decodeURIComponent(escape(s));
  }

interface Item {
    register: {
        firstName: string;
        lastName: string;
        fathersName: string;
        job: string;
        district: string;
        shop: string;
        department: string;
    },
    step1: [{ name: string; _id: string; }],
    step2: [{ name: string; _id: string; }],
    step3: [{ name: string; _id: string; }],
    step4: { department: string; district: string;firstName : string;
         fathersName: string; format: string; job: string; lastName: string;
         mail: string; phone: string; shop: string; why: string; },
    step5: string,    
}

const exporter = (data: Item[]) => {

    
     console.log(data)
    // const fieldArray = item.
    const postsForExport = data.map((item: Item) => {
         console.log(item)
         return {
             firstName : item.register.firstName,
             lastName : item.register.lastName,
             fathersName : item.register.fathersName,
             job : item.register.job,
             district : item.register.district,
             shop : item.register.shop,
             department : item.register.department,
             step1: item.step1.map( ({name})=>name).join("; "),
             step2: item.step2.map( ({name})=>name).join("; ") ,   
             step5: item.step5,             
             "hero.FirstName" : item.step4.firstName,
             "hero.LastName" : item.step4.lastName,
             "hero.FathersName" : item.step4.fathersName,     
             "hero.Format" : item.step4.format,
             "hero.Department" : item.step4.department,
             "hero.District" : item.step4.district,
             "hero.Shop" : item.step4.shop,
             "hero.Job" : item.step4.job,         
             "hero.Mail" : item.step4.mail,
             "hero.Phone" : item.step4.phone,             
             "hero.Why" : item.step4.why,    
             step3: item.step3.map ( ({name})=>name).join("; "),                         
         }
    })  
    
    const ws = utils.json_to_sheet(postsForExport)
    let wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet 1");
	let wbout = write(wb, { bookType: "xlsx", type: "binary" });
    
    saveAs(
		new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
		"registrations" + ".xlsx"
	);

    // console.log(postsForExport)
    // const csv = convertToCSV(postsForExport, {delimiter: "\t"});
    // download(csv, 'registers');   
};

const download= (csv : any, filename: string) => {
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    const bytes = csv;
  
    const blob = new Blob([bytes], { type: 'text/csv;charset=windows-1251' });
    
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `${filename}.csv`);
        fakeLink.click();
    }
};

function s2ab(s: string) {
	var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
	var view = new Uint8Array(buf); //create uint8array as viewer
	for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
	return buf;
}

// @ts-ignore
const RegistrationList = (props) => {
    return (
        <List {...props} title="Заявки" exporter={exporter} pagination={<CustomPagination />} perPage={5}>
            <Datagrid rowClick="show">
                <TextField label="Фамилия" source="register.lastName" component="pre" />
                <TextField label="Имя" source="register.firstName" component="pre" />
                <TextField label="Отчество" source="register.fathersName" component="pre" />
                <TextField label="Должность" source="register.job" component="pre" />
                <TextField label="Магазин" source="register.shop" component="pre" />
                {/*<TextField label="У нас принято" source="step1" component="pre"/>*/}
                {/*<TextField label="У нас не принято" source="step2" component="pre"/>*/}
            </Datagrid>
        </List>
    )
};

export default RegistrationList