import * as React from "react";
import {Show, TextField, TabbedShowLayout, Tab, RichTextField, ArrayField, Datagrid, EmailField} from 'react-admin';

const RegistrationShow: React.FC = (props) => (
    <Show {...props} title="Заявка сотрудника">
        <TabbedShowLayout>
            <Tab label="Сотрудник">
                <TextField label="Фамилия" source="register.lastName"/>
                <TextField label="Имя" source="register.firstName"/>
                <TextField label="Отчество" source="register.fathersName"/>
                <TextField label="Должность" source="register.job" component="pre"/>
                <TextField label="Регион" source="register.district" component="pre"/>
                <TextField label="Формат" source="register.format" component="pre"/>
                <TextField label="Бизнес-подразделение" source="register.department" component="pre"/>
                <TextField label="Магазин" source="register.shop" component="pre"/>
            </Tab>
            <Tab label="Шаг 1">
            <ArrayField source="step1" label="Ответы коллег">
                    <Datagrid>
                        <TextField label="Ответ" source="name"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Шаг 2">
            <ArrayField source="step2" label="Ответы коллег">
                    <Datagrid>
                        <TextField label="Ответ" source="name"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Шаг 3">
                <ArrayField source="step3" label="Ответы коллег">
                    <Datagrid>
                        <TextField label="Ответ" source="name"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Шаг 4">
                <TextField label="Фамилия" source="step4.lastName"/>
                <TextField label="Имя" source="step4.firstName"/>
                <TextField label="Отчество" source="step4.fathersName"/>
                <TextField label="Должность" source="step4.job" component="pre"/>
                <TextField label="Регион" source="step4.district" component="pre"/>
                <TextField label="Формат" source="step4.format" component="pre"/>
                <TextField label="Бизнес-подразделение" source="step4.department" component="pre"/>
                <TextField label="Магазин" source="step4.shop" component="pre"/>
                <TextField label="Телефон" source="step4.phone" component="pre"/>
                <EmailField label="Почта" source="step4.mail"/>
                <RichTextField label="Почему именно этот человек" source="step4.why"/>
            </Tab>
            <Tab label="Шаг 5">
                <TextField label="Фраза на майке" source="step5"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default RegistrationShow