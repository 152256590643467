import React, {useState} from "react";
import { useListContext } from 'react-admin';
import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core"

const CustomPagination = () => {
    const { page, perPage, total, setPage, setPerPage } = useListContext();

    const handleChange = (event: React.ChangeEvent<{ value: number | unknown }>) => {
        setPerPage(event.target.value as number);
    };

    const nbPages = Math.ceil(total / perPage) || 1;
    return (
        <div>
            {nbPages > 1 &&
            <Toolbar>
                {page > 1 &&
                <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
                    <ChevronLeft />
                    Назад
                </Button>
                }
                {page !== nbPages &&
                <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
                    Вперед
                    <ChevronRight />
                </Button>
                }
                <FormControl variant="outlined" style={{marginLeft: "auto", color: "red", width: "15%"}}>
                    <InputLabel style={{background: '#fff', paddingRight: '10px'}} id="demo-simple-select-outlined-label">На странице</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={perPage}
                        onChange={handleChange}
                        label="Age"
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                    </Select>
                </FormControl>
                <p style={{marginLeft: "auto", color: "red"}}>
                    {page * perPage - perPage + 1 + "-" + (page * perPage > total? total: page * perPage) + "/" + total}
                </p>
            </Toolbar>
            }
        </div>
    );
}

export default CustomPagination